<template>
  <v-container grid-list-xl style="max-width: 1540px">
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
    ></v-progress-linear>

    <form @submit.prevent="submit">
      <v-sheet elevation="2" class="pa-4">
        <v-row>
          <v-col>
            <h1>Model Detail</h1>
          </v-col>
        </v-row>
        <validation-observer ref="obs2" v-slot="{ invalid }">
          <v-row>
            <v-col>
              <validation-provider
                name="name"
                rules="required|duplicate_name"
                v-slot="{ errors, valid }"
              >
                <v-text-field
                  :readonly="!$can('create', 'analytics')"
                  v-model="analyticModel.name"
                  label="Analysis Model Name"
                  v-validate.continues="'required|duplicate_name'"
                  name="name"
                  :error-messages="errors"
                  :success="valid"
                  required
                  ref="modelNameInput"
                  outlined
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto" md sm="12">
              <validated-select-field
                :items="resourceTypeOptions"
                :readonly="!$can('delete', 'analytics')"
                v-model="resourceType"
                label="Resource Type"
                hint="What resource type do you want to analyze"
                persistent-hint
                @change="setEndUseOptions"
                ref="resourceTypeSelect"
              ></validated-select-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto" md sm="12">
              <validated-select-field
                rules="required"
                :readonly="!$can('delete', 'analytics')"
                :items="analysisTypes"
                item-value="id"
                item-text="analysisTypeName"
                v-model="analyticModel.analysisType"
                label="Select Analysis Type"
                hint="What type of analysis do you want to perform"
                persistent-hint
                ref="analysisTypeSelect"
                returnObject
              ></validated-select-field>
            </v-col>

            <v-col cols="auto" md="6" sm="12">
              <validated-select-field                
                :readonly="!$can('delete', 'analytics')"
                :items="endUseOptions"
                v-model="selectedEndUses"
                label="Select End Use(s)"
                persistent-hint
                hint="Select end uses for analysis"
                @change="setEndUses"
                ref="endUseSelect"
                multiple
              ></validated-select-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto" md="6" sm="12">
              <validated-select-field
                rules="occupancy_dayOfWeek"
                :items="factorOptions"
                v-model="selectedFactors"
                label="Select Factor(s)"
                multiple
                persistent-hint
                hint="Select factors for analysis"
                @change="setFactors"
                ref="factorSelect"
              ></validated-select-field>
            </v-col>
            <v-col cols="auto" md="6" sm="12">
              <validated-select-field                
                :items="nreOptions"
                item-text="name"
                item-value="id"
                label="Select NRE(s)"
                multiple
                persistent-hint
                hint="Select NRE(s) for analysis"
                ref="nreSelect"
                return-object
                @input="handleSetNREs"
              ></validated-select-field>
            </v-col>
          </v-row>
          <v-layout wrap justify-space-between>
            <v-flex xs12 md6>
              <v-subheader class="pl-0"
                >Basis for Cooling Degree Days</v-subheader
              >
              <v-row>
                <v-slider
                  v-model="analyticModel.degreeDaysBaseCooling"
                  :min="40"
                  :max="120"
                  hide-details
                >
                  <template v-slot:append>
                    <validation-provider
                      name="degree days base cooling"
                      rules="required|valid_degreeDays_range"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="analyticModel.degreeDaysBaseCooling"
                        v-validate.continues="'required|valid_degreeDays_range'"
                        name="degree days base cooling"
                        class="mt-0 pt-0"
                        hide-details
                        type="number"
                        style="width: 60px"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </template>
                </v-slider>
              </v-row>
              <div class="red--text caption">
                {{ errors.first("degree days base cooling") }}
              </div>
            </v-flex>

            <v-flex xs12 md6>
              <v-subheader class="pl-0"
                >Basis for Heating Degree Days</v-subheader
              >
              <v-row>
                <v-slider
                  v-model="analyticModel.degreeDaysBaseHeating"
                  :min="40"
                  :max="120"
                  hide-details
                >
                  <template v-slot:append>
                    <validation-provider
                      name="degree days base heating"
                      rules="required|valid_degreeDays_range"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="analyticModel.degreeDaysBaseHeating"
                        v-validate.continues="'required|valid_degreeDays_range'"
                        name="degree days base heating"
                        class="mt-0 pt-0"
                        hide-details
                        type="number"
                        style="width: 60px"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </template>
                </v-slider>
              </v-row>
              <div class="red--text caption">
                {{ errors.first("degree days base heating") }}
              </div>
            </v-flex>
          </v-layout>
          <start-end-date-picker
            name="date range"
            rules="required|valid_date_range"
            v-model="dateRange"
            @change="setDateRange"
            date-format="yyyy-MM-dd"
            ref="startEndDatePicker"
          ></start-end-date-picker>
          <v-row>
            <label class="pa-3">Comments</label>
            <v-textarea
              rows="2"
              clearable
              clear-icon="mdi-close-circle"
              outlined
              auto-grow
              v-model="analyticModel.comment"
              class="pr-3"
            >
            </v-textarea>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                v-if="$can('create', 'analytics')"
                @click="validateModel"
                color="primary"
                class="mr-5"
                :disabled="invalid"
                >Validate</v-btn
              >
              <v-btn
                @click="analyzeModel"
                color="primary"
                class="mr-5"
                :disabled="invalid || !isValidModel"
                >Analyze</v-btn
              >
              <v-btn
                @click="downloadZip"
                title="download the raw data and results for this model in csv format"
                color="primary"
                class="mr-5"
                :disabled="invalid || !isValidModel"
                >Download Raw Data</v-btn
              >
              <v-btn
                title="download a pdf description of this analysis"
                color="primary"
                class="mr-5"
                :disabled="invalid || !isValidModel"
                @click="downloadPDF"
                download
                >Download Report</v-btn
              >
              <v-btn
                v-if="$can('create', 'analytics')"
                type="submit"
                color="secondary"
                class="mr-5"
                
                >Save</v-btn
              >
              <v-btn @click="handleCancelCrud">Cancel</v-btn>
            </v-col>
          </v-row>
          <v-row justify="center">
            <stepper-validate
              :invalid="invalid"
              :modelName="analyticModel.name"
              :factors="selectedFactors"
              :startDateTime="dateRange.startDateTime"
              :endDateTime="dateRange.endDateTime"
              :dateRange="dateRange"
              :nameValidationResult="nameValidationResult"
              :dataValidationResult="dataValidationResult"
              @dismissdialog="dismissDialog"
              @fixmodel="fixModelErrors"
              ref="stepper"
            ></stepper-validate>
          </v-row>

          <v-row justify="center">
            <stepper-analyze
              @dismissanalysisdialog="dismissAnalysisDialog"
              ref="analyzer"
            ></stepper-analyze>
          </v-row>
        </validation-observer>
      </v-sheet>
    </form>

    <v-sheet>
      <highcharts :options="chartOptions" ref="evalChart" v-if="showChart" />
    </v-sheet>
  </v-container>
</template>
<style>
.v-input__icon.v-input__icon--clear {
  margin-top: 2px !important;
}
</style>
<script>
import { Chart } from "highcharts-vue";
import {
  ValidationProvider,
  ValidationObserver,
  Validator,
} from "vee-validate";
import moment from "moment";
import StartEndDatePicker from "@/components/Fields/StartEndDatePicker";
import ValidatedSelectField from "@/components/Fields/ValidatedSelectField";
import StepperValidate from "./StepperValidate.vue";
import StepperAnalyze from "./StepperAnalyze.vue";
import api from "../../analytics_models/_api";

import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import goTo from "vuetify/es5/services/goto";

export default {
  name: "AnalyticsModelDetailsModule",
  components: {
    highcharts: Chart,
    "validation-provider": ValidationProvider,
    "validation-observer": ValidationObserver,
    "start-end-date-picker": StartEndDatePicker,
    "validated-select-field": ValidatedSelectField,
    "stepper-validate": StepperValidate,
    "stepper-analyze": StepperAnalyze,
  },

  data() {
    return {
      analyticModels: [],
      analyticModel: {
        analyticFactors: [],
        analyticType: {
          id: 1,
          analyticTypeName: "MandV",
        },
        analysisType: {
          id: 0,
          analysisTypeName: "Linear Regression",
        },
        degreeDaysBaseCooling: 65,
        degreeDaysBaseHeating: 65,
        startDateTime: null,
        endDateTime: null,
        nonRoutineEvents: []
      },
      error: null,
      loading: true,

      name: "Analytics Model Detail",

      dateRange: {},
      dataValidationResult: null,
      nameValidationResult: null,

      nameIndex: 1,
      energyIndex: 2,
      weatherIndex: 3,
      factorIndex: 4,

      isValidModel: false,
      isValidFactor: false,
      siteId: this.$route.params.siteId,
      selectedEndUses: [],
      selectedFormula: "",
      selectedAnalysisTypeId: null,
      resourceType: "",
      selectedFactors: [],      

      endUses: [],
      endUseOptions: [],
      analysisTypes: [],
      nreOptions: [],
      formulaOptions: [
        { value: "1", text: "Production Capacity" },
        { value: "2", text: "Adjusted Occupancy" },
      ],
      factorOptions: [],
      resourceTypeOptions: [
        { value: "Electricity", text: "Electricity" },
        { value: "Gas", text: "Gas" },
        { value: "Water", text: "Water" },
        { value: "Solar", text: "Solar" },
      ],

      startMenu: false,
      endMenu: false,
      showChart: false,

      chartOptions: {
        credits: { enabled: false },
        chart: {
          type: "line",
          zoomType: "x",
          borderWidth: 1,
          borderColor: "#efefef",
        },
        title: {
          text: "Model Predictions",
        },
        subtitle: {
          text: "R2: .78 - NMBE: 4.03% - CV(RMSE): 37.1%",
        },
        xAxis: {
          type: "datetime",
          crosshair: true,
          labels: {
            rotation: -45,
            formatter: function () {
              if (!this.value) return "";

              return moment(new Date(this.value)).format("YYYY-M-D");
            },
          },
        },
        yAxis: {
          title: {
            text: "kWh",
          },
        },
        tooltip: {
          pointFormat: "{point.y}",
          valueSuffix: "kWh",
          valueDecimals: "4",
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false,
              symbol: "circle",
              radius: 2,
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
        },
        series: [{}],
      },
    };
  },

  async mounted() {
    this.dateRange = {
      startDateTime: new Date().toISOString().substring(0, 10),
      endDateTime: new Date().toISOString().substring(0, 10),
    };
  },

  async created() {
    this.getAnalyticFactors();
    this.getAnalysisTypes();
    this.getEndUses();
    this.getNREs();
    this.registerCustomValidators();

    this.$validator.attach;
  },

  computed: {
    ...mapGetters("session", ["jwtName", "jwtPicture"]),
  },

  methods: {
    ...mapActions({}),

    getAvatar() {
      return this.jwtPicture;
    },

    setFormula() {},

    setDateRange(e) {
      this.analyticModel.startDateTime = e.startDateTime;
      this.analyticModel.endDateTime = e.endDateTime;

      this.dateRange = {
        startDateTime: e.startDateTime,
        endDateTime: e.endDateTime,
      };
    },

    submit() {
      this.postModel();
    },

    async downloadZip() {
      try {
        this.loading = true;
        let zipStream = await api.downLoadAnalyticModelZip(
          this.siteId,
          this.analyticModel
        );
        let fileURL = window.URL.createObjectURL(new Blob([zipStream]));
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.analyticModel.name + ".zip");
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },

    async downloadPDF() {
      try {
        this.loading = true;
        let zipStream = await api.downLoadAnalyticModelPDF(
          this.siteId,
          this.analyticModel
        );
        let fileURL = window.URL.createObjectURL(new Blob([zipStream]));
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.analyticModel.name + ".pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },

    async postModel() {
      let self = this;
      let isValid = await this.$refs.obs2.validate();
      if (isValid) {
        var newAnalyticModel = {
          modelName: self.analyticModel.name,
          siteId: self.siteId,
          siteName: null,
          start: self.analyticModel.startDateTime,
          end: self.analyticModel.endDateTime,
          analysisTypeName: self.analyticModel.analysisType.analysisTypeName,
          analysisTypeId: self.analyticModel.analysisType.id,
          analyticTypeId: self.analyticModel.analyticType.id,
          analyticTypeName: self.analyticModel.analyticType.analyticTypeName,
          degreeDaysBaseCooling: self.analyticModel.degreeDaysBaseCooling,
          degreeDaysBaseHeating: self.analyticModel.degreeDaysBaseHeating,
          comment: self.analyticModel.comment,          
          factors: self.analyticModel.analyticFactors,
          endUses: self.analyticModel.endUses,
          nonRoutineEvents: self.analyticModel.nonRoutineEvents
        }
        await api.createAnalyticModel(self.siteId, newAnalyticModel).then((response) =>{
          console.log(response);
          this.$toast.show("Model created", null, "success");
          this.$router.push({ name: "AnalyticsModelIndex" });
          this.loading = false;
        }).catch((error) => {
          console.log(error);
        }).finally(() => {
          this.loading = false
        }); 
      }
    },

    //ENDUSES - START
    async getEndUses() {
      let self = this;
      await api.getEndUses(self.siteId)
        .then((response) => {
          self.endUses = response
          self.endUseOptions = self.mapEndUseLookup(
            self.endUses,
            self.resourceType
          );
          self.selectedEndUses = self.mapAnalyticsEndUses(
            self.analyticModel.endUses
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },

    setEndUses(item) {
      this.analyticModel.endUses = this.mapSelectedEndUses(item);
    },

    mapSelectedEndUses(endUses) {
      const makeEndUse = (selectedItem) => {
        var selectedEndUseOption = this.endUseOptions.find(
          (o) => o.value === selectedItem
        );

        return {
          endUseId: selectedItem,
          endUseName: selectedEndUseOption.text,
          resourceType: "Electricity", //get this value from the resource type selection
          endUseUnitOfMeasureName: selectedEndUseOption.endUseUnitOfMeasureName,
          selected: true,
          siteId: this.siteId, //temporary
        };
      };

      var eu = endUses.map(makeEndUse);
      return eu;
    },

    mapAnalyticsEndUses(enduses) {
      const extractEndUse = (item) => {
        var endUseToExtract = this.endUseOptions.find(
          (o) => o.text === item.endUseName
        );
        return endUseToExtract.value;
      };

      var af = enduses.map(extractEndUse);
      return af;
    },

    setEndUseOptions(selectedResource) {
      this.endUseOptions = this.mapEndUseLookup(this.endUses, selectedResource);
    },

    mapEndUseLookup(endUses, resourceTypeName) {
      const makeOptionItems = (item) => {
        if (item.resourceType === resourceTypeName) {
          return {
            value: item.endUseId,
            text: item.endUseName,
            endUseUnitOfMeasureName: item.endUseUnitOfMeasureName,
            resourceType: item.resourceType,
          };
        }
      };

      var eul = endUses.map(makeOptionItems);
      return eul.filter((e) => {
        return e != undefined;
      });
    },
    //ENDUSES - END


    //ANALYSISTYPES - START
    async getAnalysisTypes() {
      let self = this;
      api
        .getAnalysisTypes()
        .then((response) => {
          self.analysisTypes = response;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    //ANALYSISTYPES - END


    //FACTORS - START
    async getAnalyticFactors() {
      let self = this;
      api
        .getAnalyticFactors()
        .then((response) => {
           self.factorOptions = self.mapFactorLookup(response);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },

    mapFactorLookup(endUses) {
      const makeOptionItems = (item) => {
        return {
          value: item.id,
          text: item.factorName,
          analyticFactorName: item.analyticFactorName,
        };
      };
      var fl = endUses.map(makeOptionItems);
      return fl;
    },

    setFactors(items) {
      this.analyticModel.analyticFactors = this.mapSelectedFactors(items);
    },

    mapSelectedFactors(factors) {
      const makeFactor = (selectedItem) => {
        var selectedFactorOption = this.factorOptions.find(
          (o) => o.value === selectedItem
        );

        return {
          id: selectedItem,
          analyticFactorName: selectedFactorOption.analyticFactorName,
          factorName: selectedFactorOption.text,
          selected: true,
        };
      };

      var sf = factors.map(makeFactor);
      return sf;
    },

    mapanalyticFactors(factors) {
      const extractFactor = (item) => {
        var factorToExtract = this.factorOptions.find(
          (o) => o.analyticFactorName == item.analyticFactorName
        );
        return factorToExtract.value;
      };

      var af = factors.map(extractFactor);
      return af;
    },
    //FACTORS - END


    //NRES - START
    async getNREs() {
      let self = this;
      api
        .getNREs(self.siteId)
        .then((response) => {
          self.nreOptions = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleSetNREs(selectedNREs) {
      let self = this;
      self.analyticModel.nonRoutineEvents = selectedNREs.map(x => { return {...x, selected: true}});
    },
    //NRES -END


    registerCustomValidators() {
      let self = this;
      Validator.extend("duplicate_name", {
        // Custom validation message
        getMessage: (field) =>
          `The ${field} is already used by another model.  Enter a different name.`,
        // Custom validation rule
        validate: (value) =>
          new Promise((resolve) => {
            let existingNames = self.analyticModels.map((a) => a.name);
            let valid = true;
            valid = existingNames.indexOf(value) === -1;
            resolve({
              valid: valid,
            });
          }),
      });

      Validator.extend("valid_degreeDays_range", {
        // Custom validation message
        getMessage: (field) =>
          `Value for ${field} must be greater than 40 and less than 120.`,
        // Custom validation rule
        validate: (value) =>
          new Promise((resolve) => {
            let withinRange = value > 40 && value < 120;
            resolve({
              valid: withinRange,
            });
          }),
      });

      Validator.extend("formula_or_factors", {
        // Custom validation message
        getMessage: (field) =>
          `You have factors selected.  Clear factors before selecting the ${field} formula`,
        // Custom validation rule
        validate: (value) =>
          new Promise((resolve) => {
            console.log(value);
            let noFactors = self.selectedFactors.length === 0;
            resolve({
              valid: noFactors,
            });
          }),
      });

      Validator.extend("occupancy_dayOfWeek", {
        // Custom validation message
        getMessage: (field) =>
          `Occupancy and Day Of Week cannot both be ${field}.`,
        // Custom validation rule
        validate: (value) =>
          new Promise((resolve) => {
            var validation = ["Day Of Week", "Occupancy"];
            let noConflict = true;
            const makeFactor = (selectedItem) => {
              return self.factorOptions.find((o) => o.text === selectedItem)
                .value;
            };
            var validationIds = validation.map(makeFactor);
            if (validationIds.every((item) => value.includes(item))) {
              noConflict = false;
            }
            resolve({
              valid: noConflict,
            });
          }),
      });
    },

    mapChartSeriesData(predictions) {
      const makeSeriesDataPoint = (item, property) => {
        var unixDate = moment(item.date).valueOf();
        var value = item[property];
        return [unixDate, value];
      };
      var seriesData = [
        {
          name: "Actual",
          data: predictions.map((p) => makeSeriesDataPoint(p, "kWh")),
        },
        {
          name: "Predicted",
          data: predictions.map((a) => makeSeriesDataPoint(a, "prediction")),
        },
      ];
      return seriesData;
    },

    ///Validate Dialog
    async validateModel() {
      let isValid = await this.$refs.obs2.validate();
      if (!isValid) {
        return;
      }
      this.nameValidationResult = await api.validateAnalyticModelNameForCreate(
        this.siteId,
        this.analyticModel.name
      );
      this.dataValidationResult = await api.validateAnalyticModelDataForRange(
        this.siteId,
        this.analyticModel.startDateTime,
        this.analyticModel.endDateTime
      );
      this.isValidFactor = this.selectedFactors.length > 0;
      this.isValidModel =
        this.dataValidationResult.validation &&
        this.nameValidationResult &&
        this.isValidFactor;
      this.$refs.stepper.validateModel();
    },

    fixModelErrors(validationItem) {
      let step = validationItem.item;
      let newDateRange = validationItem.newDateRange;
      let self = this;
      switch (step) {
        case this.nameIndex:
          this.$nextTick().then(() => {
            goTo(0);
            self.$refs.modelNameInput.$refs.input.focus();
          });
          break;
        case this.factorIndex:
          this.$nextTick().then(() => {
            self.$refs.factorSelect.$refs.validateSelectField.focus();
          });
          break;
        case this.weatherIndex:
        case this.energyIndex:
          this.$nextTick().then(() => {
            self.dateRange = newDateRange;
            self.$refs.startEndDatePicker.$refs.startPicker.focus();
          });
          break;
        default:
          break;
      }

      this.isValidModel = false;
    },

    dismissDialog() {
      console.log("dialog dismissed");
    },

    ///Analysis Dialog
    analyzeModel() {
      this.$refs.analyzer.analyzeModel();
    },

    async dismissAnalysisDialog() {
      await this.completeAnalysis();
    },

    async completeAnalysis() {
      let self = this;

      let isValid = await this.$refs.obs2.validate();

      if (isValid) {
        this.$toast.show(
          "Analyzing current analytics model " + self.analyticModel.name,
          null,
          "success"
        );
        this.axios2
          .post(
            "/v1/analytics/energy/sites/" +
              self.siteId +
              "/weathernormalized/predictions",
            self.analyticModel
          )
          .then((response) => {
            if (self.currentVersion !== undefined) {
              self.currentVersion.analyzedDateTime = moment(new Date()).format(
                "YYYY-MM-DD hh:mm A"
              );
            }
            var seriesData = self.mapChartSeriesData(
              response.data.data.predictions
            );
            var stats = response.data.data.statistics;

            self.chartOptions.series = seriesData;
            self.chartOptions.subtitle.text =
              "R2: " +
              stats.rSquared +
              " - NMBE: " +
              stats.nmbe +
              "% - CV(RMSE): " +
              stats.cvrmse +
              "%";

            self.showChart = true;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => (self.loading = false));
      }
    },

    handleCancelCrud() {
      this.$router.push({ name: "AnalyticsModelIndex" });
    },
  },
};
</script>
