<template>
    <v-dialog v-model="analysisDialog" max-width="600" persistent>
    <v-card>
        <v-card-title class="headline">Running model</v-card-title>
            <v-card-text>
                <!-- Analyze Stepper -->
                <v-stepper v-model="analysisVSteps" vertical>
                <!--Accessing Energy Data-->
                <v-stepper-step
                    :complete="analysisVSteps > 1"
                    step="1"
                    color="green"
                >
                    Accessing Energy Data
                    <small v-if="analysisVSteps > 1">{{"Accessed Energy Data"}}</small>
                </v-stepper-step>
                <v-stepper-content step="1">
                    <v-progress-linear
                    indeterminate
                    color="green"
                    ></v-progress-linear>
                </v-stepper-content>

                <!--Accessing weather data-->
                <v-stepper-step
                    :complete="analysisVSteps > 2"
                    step="2"
                    color="green"
                >
                    Accessing weather data
                    <small v-if="analysisVSteps > 2">{{"Accessed weather data"}}</small>
                </v-stepper-step>
                <v-stepper-content step="2">
                    <v-progress-linear
                    indeterminate
                    color="green"
                    ></v-progress-linear>
                </v-stepper-content>

                <!--Calculating CDD and HDD-->
                <v-stepper-step
                    :complete="analysisVSteps > 3"
                    step="3"
                    color="green"
                >
                    Calculating CDD and HDD
                    <small v-if="analysisVSteps > 3">{{"Calculated CDD and HDD"}}</small>
                </v-stepper-step>
                <v-stepper-content step="3">
                    <v-progress-linear
                    indeterminate
                    color="green"
                    ></v-progress-linear>
                </v-stepper-content>

                <!--Running regression analysis-->
                <v-stepper-step
                    :complete="analysisVSteps > 4"
                    step="4"
                    color="green"
                >
                    Running regression analysis
                    <small v-if="analysisVSteps > 4">{{"Ran regression analysis"}}</small>
                </v-stepper-step>
                <v-stepper-content step="4">
                    <v-progress-linear
                    indeterminate
                    color="green"
                    ></v-progress-linear>
                </v-stepper-content>

                <!--Calculating intercept and coefficients-->
                <v-stepper-step
                    :complete="analysisVSteps > 5"
                    step="5"
                    color="green"
                >
                Calculating intercept and coefficients
                    <small v-if="analysisVSteps > 5">{{"Calculated intercept and coefficients"}}</small>
                </v-stepper-step>
                <v-stepper-content step="5">
                    <v-progress-linear
                    indeterminate
                    color="green"
                    ></v-progress-linear>
                </v-stepper-content>

                <!--Rendering chart-->
                <v-stepper-step
                    :complete="analysisVSteps > 6"
                    step="6"
                    color="green"
                >
                Rendering chart
                    <small v-if="analysisVSteps > 6">{{"Rendered chart"}}</small>
                </v-stepper-step>
                <v-stepper-content step="6">
                    <v-progress-linear
                    indeterminate
                    color="green"
                    ></v-progress-linear>
                </v-stepper-content>

                </v-stepper>
            </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dismissAnalysisDialog">Continue</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mixin as VueTimers } from "vue-timers";

export default {
    components: {
    },

    timers: {
        analysisMetaValidator: { time: 500, autostart: false, repeat: true },
    },

    mixins: [VueTimers],

    props: {
    },

    data() {
        return {
            analysisDialog: false,
            analysisVSteps: 0
        };
    },

    async created() {
    },

    methods: {

        analyzeModel() {
            this.analysisDialog = true;
            this.$timer.start("analysisMetaValidator");
        },

        analysisMetaValidator() {
            this.analysisVSteps++;
        },

        cancelDialog() {
            this.analysisDialog = false;
            this.$timer.stop("analysisMetaValidator");
            this.analysisVSteps = 0;
        },

        dismissAnalysisDialog() {
            this.cancelDialog();
            this.$emit("dismissanalysisdialog", true);
        }

    }
};
</script>